import { PERIOD, PERIOD_DESCRIPTIONS } from '@/utils/enums/common';

export enum ABSENCE_TYPE_ICON {
    CATEGORY = 'category',
    BABY_CHANGING_STATION = 'baby_changing_station',
    ESCALATOR_WARNING = 'escalator_warning',
    CHILD_CARE = 'child_care',
    CHILD_FRIENDLY = 'child_friendly',
    LOCAL_FLORIST = 'local_florist',
    MONEY_OFF = 'money_off',
    SCHOOL = 'school',
    ERROR = 'error',
    WORKSPACES = 'workspaces',
    GRADE = 'grade',
    FLAG = 'flag',
    ACCOUNT_CIRCLE = 'account_circle',
    CAKE = 'cake',
    LIGHT_MODE = 'light_mode',
    ADD = 'add_box',
    HOME = 'home',
    EVENT_BUSY = 'event_busy',
    MAPS_HOME_WORK = 'maps_home_work',
    SPORTS_TENNIS = 'sports_tennis',
    EVENT_NOTE = 'event_note',
    CALL = 'call',
    CLOUD_QUEUE = 'cloud_queue',
    CORONAVIRUS = 'coronavirus',
    DIRECTIONS_CAR_FILLED = 'directions_car_filled',
    LUGGAGE = 'luggage',
    PUSH_PIN = 'push_pin',
    EMOJI_EVENTS = 'emoji_events',
    MAP = 'map',
    EMAIL = 'email',
    NOTIFICATIONS = 'notifications',
    PALETTE = 'palette',
    DIRECTIONS_BIKE = 'directions_bike',
    RECEIPT_LONG = 'receipt_long',
    EXPLORE = 'explore',
    LOCATION_ON = 'location_on',
    VOLUNTEER_ACTIVISM = 'volunteer_activism',
    ACCESSIBLE = 'accessible',
    HEALING = 'healing',
    HEALTH_AND_SAFETY = 'health_and_safety',
    HISTORY = 'history',
    HIVE = 'hive',
    ASSIGNMENT = 'assignment',
    LANGUAGE = 'language',
    HOTEL = 'hotel',
    AUTORENEW = 'autorenew',
    HUB = 'hub',
    BADGE = 'badge',
    SENTIMENT_SATISFIED = 'sentiment_satisfied',
    ALARM = 'alarm',
    VISIBILITY = 'visibility',
    BEDTIME = 'bedtime',
    BLOCK = 'block',
    WORK_OUTLINED = 'work_outlined',
    WORK_OFF_OUTLINED = 'work_off_outlined',
}
export const ABSENCE_TYPES_ICONS = Object.values(ABSENCE_TYPE_ICON);

export const DEFAULT_ICON = ABSENCE_TYPE_ICON.CATEGORY;

export enum PREDEFINED_ABSENCE_TYPE {
    ILL = 'ILL',
    HOME = 'HOME',
    ON_DEMAND = 'ON_DEMAND',
    HOLIDAY = 'HOLIDAY',
}

export enum ABSENCE_TYPES_PAGE_VIEW {
    MAIN = 'MAIN',
    EDIT_CREATE = 'EDIT_CREATE',
}

export const PERIODS_OPTIONS = Object.entries(PERIOD_DESCRIPTIONS).map(([value, label]) => ({
    value,
    label,
}));

export const WEEK_MAX_DAYS = 7;
export const MONTH_MAX_DAYS = 31;
export const YEAR_MAX_DAYS = 366;

export const MAX_DAYS_MAP: Record<PERIOD, number> = {
    WEEK: WEEK_MAX_DAYS,
    MONTH: MONTH_MAX_DAYS,
    YEAR: YEAR_MAX_DAYS,
    SINGLE_USE: YEAR_MAX_DAYS,
};
