import mixpanel from 'mixpanel-browser';

import {
    FQ_NODE_ENV,
    MIXPANEL_TOKEN_DEV,
    MIXPANEL_TOKEN_PRODUCTION,
    MIXPANEL_TOKEN_STAGE,
} from '@/utils/constants/common';

const EXCLUDED_ORGANIZATION_IDS = ['d34afa29-9c29-4e64-851b-3eea12fbf93a'];

const selectEnvironment = () => {
    if (FQ_NODE_ENV === 'development') return MIXPANEL_TOKEN_DEV;
    if (FQ_NODE_ENV === 'stage') return MIXPANEL_TOKEN_STAGE;
    if (FQ_NODE_ENV === 'production') return MIXPANEL_TOKEN_PRODUCTION;

    return '';
};

export const initializeMixpanel = () => {
    const mixPanelInit = () => mixpanel.init(selectEnvironment(), { debug: FQ_NODE_ENV !== 'production' });
    environmentGuard(mixPanelInit);
};

export type MixPanelProps = Record<string, string | number | boolean | undefined | null>;

const environmentGuard = (callback: () => void) => {
    if (!FQ_NODE_ENV) {
        return;
    }

    callback();
};

export const initMixpanelProps = ({ userId, organizationId }) => {
    const callMixPanelActions = () => {
        if (EXCLUDED_ORGANIZATION_IDS.includes(organizationId)) {
            mixpanel.opt_out_tracking();
            console.warn('Disabled tracking for organization:', organizationId);
            return;
        }

        mixpanel.identify(userId);
        mixpanel.register_once({ organizationId });
    };

    environmentGuard(callMixPanelActions);
};

export const resetMixpanel = () => {
    const callMixpanelReset = () => {
        try {
            mixpanel.reset();
        } catch (err) {
            console.error(err);
        }
    };

    environmentGuard(callMixpanelReset);
};

export function mixpanelTracking(mixpanelAction: MIXPANEL_ACTIONS, props?: MixPanelProps) {
    const trackMixPanel = () => mixpanel.track(mixpanelAction, props);
    environmentGuard(trackMixPanel);
}

export enum MIXPANEL_ACTIONS {
    OPEN_APP = 'OpenApp',
    LOGIN = 'Login',
    START_REGISTER_ORGANIZATION = 'StartRegisterOrganization',
    REGISTER_ORGANIZATION_SUCCESS = 'RegisterOrganizationSuccess',
    REGISTER_ORGANIZATION_FAILED = 'RegisterOrganizationFailed',
    NEW_REQUEST = 'NewRequest',
    APPROVED_REQUEST = 'ApprovedRequest',
    REJECTED_REQUEST = 'RejectedRequest',
    DELETED_REQUEST = 'DeletedRequest',
    PAYMENT_REDIRECTION_FAILED = 'PaymentRedirectionFailed',
    PAYMENT_CANCELED = 'PaymentCanceled',
    LAST_EMPLOYEE_INVITED = 'LastEmployeeInvited',
    TRY_UNBLOCKING_EMPLOYEE_OVER_LIMIT_EXCEEDED = 'TryUnblockingEmployeeOverLimit',
    MY_SUBSCRIPTIONS_CLICK_ON_UPGRADE_BANNER = 'MySubscriptionsClickOnUpgradeBanner',
    MY_SUBSCRIPTIONS_CLICK_ON_UPGRADE_MODAL = 'MySubscriptionsClickOnUpgradeModal',
    MY_SUBSCRIPTIONS_CLICK_AFTER_LAST_INVITE = 'MySubscriptionsClickAfterLastInvite',
    FIRST_PAYMENT_SUCCESS = 'FirstPaymentSuccess',
    LANDINGPAGE_PAYMENT_FAILED = 'LandingPagePaymentFailed',
    EMPLOYEE_LIST_VIEWED = 'EmployeeListViewed',
    EMPLOYEE_BLOCKED = 'EmployeeBlocked',
    EMPLOYEE_UNBLOCKED = 'EmployeeUnblocked',
    INVITATION_TAB_VIEWED = 'InvitationTabViewed',
    NEW_EMPLOYEE_INVITED = 'NewEmployeeInvited',
    NEW_EMPLOYEE_ACCOUNT_ACTIVATED = 'NewEmployeeAccountActivated',
    DELETE_ORGANIZATION_SUCCESSFUL = 'DeleteOrganisationSuccessful',
    REQUEST_TYPE_ADDED = 'RequestTypeAdded',
    REQUEST_TYPE_EDITED = 'RequestTypeEdited',
    REQUEST_TYPE_REMOVED = 'RequestTypeRemoved',
    REQUEST_TYPE_PERSONALIZED = 'RequestTypePersonalized',
    PERSONALIZE_REQUEST_VIEWED = 'PersonalizeRequestViewed',
    NEW_DAY_OFF_ADDED = 'NewDayOffAdded',
    HOLIDAYS_IMPORT_CLICKED = 'HolidaysImportClicked',
    HOLIDAYS_IMPORT_SUCCESSFUL = 'HolidaysImportSuccessful',
    USER_PROFILE_VIEWED = 'UserProfileViewed',
    USER_PERMISSION_CHANGED = 'UserPermissionChanged',
    APPROVER_ADDED_TO_USER = 'ApproverAddedToUser',
    APPROVER_REMOVED_FROM_USER = 'ApproverRemovedFromUser',
    REPORTS_EXPORTED = 'ReportExported',
    REPORTS_TAB_VIEWED = 'ReportsTabViewed',
    REPORTS_DETAILS_FOR_USER_VIEWED = 'ReportsDetailsForUserViewed',
    ONBOARDING_MODAL = 'OnboardingModal',
    ONBOARDING_MODAL_CTA = 'OnboardingModalCTA',
    ONBOARDING_STEP_1 = 'OnboardingStep1',
    ONBOARDING_STEP_2 = 'OnboardingStep2',
    ONBOARDING_STEP_3 = 'OnboardingStep3',
    ONBOARDING_STEP_4_FORM = 'OnboardingStep4_form',
    ONBOARDING_STEP_4_TEXT = 'OnboardingStep4_text',
    ONBOARDING_STEP_5 = 'OnboardingStep5',
    ONBOARDING_STEP_1_ERROR = 'OnboardingStep1Error',
    ONBOARDING_STEP_2_ERROR = 'OnboardingStep2Error',
    ONBOARDING_STEP_3_ERROR = 'OnboardingStep3Error',
    ONBOARDING_STEP_4_ERROR = 'OnboardingStep4Error',
}
