import { FieldConfig } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { Holiday } from '@/domain/myOrganization';
import { COLOR } from '@/globalStyles';
import {
    ABSENCE_TYPE_ICON,
    PREDEFINED_ABSENCE_TYPE,
} from '@/pages/AdministratorPanel/ManageRequests/AbsenceTypes/constants';

export interface OnboardingFormData extends FieldConfig {
    selectedCountry: {
        name: string;
        countryCode: string;
    };
    days: WeekDays;
    holidays: Holiday[];
    requestTypes: Record<PREDEFINED_ABSENCE_TYPE, RequestTypeField>;
    invite: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

enum Day {
    monday = 0,
    tuesday = 1,
    wednesday = 2,
    thursday = 3,
    friday = 4,
    saturday = 5,
    sunday = 6,
}
type WeekDays = Record<Day, boolean>;

export type RequestTypeField = {
    isActive: boolean;
    id: string;
    color: string;
    icon: string;
    name: string;
};

const requestTypeField = {
    isActive: false,
    id: '',
    color: COLOR.blue,
    icon: ABSENCE_TYPE_ICON.ADD,
    name: '',
};

export const DEFAULT_REQUEST_TYPES_FORM = {
    [PREDEFINED_ABSENCE_TYPE.HOLIDAY]: requestTypeField,
    [PREDEFINED_ABSENCE_TYPE.ILL]: requestTypeField,
    [PREDEFINED_ABSENCE_TYPE.ON_DEMAND]: requestTypeField,
    [PREDEFINED_ABSENCE_TYPE.HOME]: requestTypeField,
};

export const onboardingFormData = {
    selectedCountry: {
        name: '',
        countryCode: '',
    },
    days: {
        0: false,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: false,
    },
    holidays: [],
    requestTypes: DEFAULT_REQUEST_TYPES_FORM,
    invite: {
        firstName: '',
        lastName: '',
        email: '',
    },
};

export const holidaysStepValidationSchema = Yup.object({
    selectedCountry: Yup.object({
        name: Yup.string().required(t('forms_field_required')),
        countryCode: Yup.string().required(t('forms_field_required')),
    }),
});

export const handleInvitationStepValidationSchema = (isUserInvited: boolean) => {
    if (isUserInvited) {
        return Yup.object({});
    }
    return Yup.object({
        invite: Yup.object({
            firstName: Yup.string().required(t('forms_field_required')),
            lastName: Yup.string().required(t('forms_field_required')),
            email: Yup.string().email(t('auth_common_wrong_email_error')).required(t('forms_field_required')),
        }),
    });
};

export const onboardingSteps = () => {
    return [
        t('working_days_tab_label'),
        t('days_off_tab_label'),
        t('absence_types_tab_label'),
        t('invitations_tab_label'),
    ];
};

export enum ONBOARDING_VIEWS {
    START = 'START',
    FORM = 'FORM',
    FINISH = 'FINISH',
}

export const getOnboardingViewTranslation = (view: ONBOARDING_VIEWS) => {
    switch (view) {
        case ONBOARDING_VIEWS.START:
            return t('onboarding_welcome_title');
        case ONBOARDING_VIEWS.FORM:
            return t('onboarding_title');
        case ONBOARDING_VIEWS.FINISH:
            return t('onboarding_summary_screen_title');
        default:
            return '';
    }
};

export const getOnboardingProgressTranslation = (progressLength: number) => {
    switch (progressLength) {
        case 0:
            return t('onboarding_progress_0');
        case 1:
            return t('onboarding_progress_25');
        case 2:
            return t('onboarding_progress_50');
        case 3:
            return t('onboarding_progress_75');
        case 4:
            return t('onboarding_progress_100');
        default:
            return t('onboarding_progress_0');
    }
};

export const startButtonTranslation = (progressLength: number) => {
    if (progressLength === 0) {
        return t('onboarding_start');
    }
    return t('common_continue');
};

export const onboardingInitiation = (isCompanyOwner?: boolean) => {
    if (!isCompanyOwner) return;
    const itemsToSet = {
        onboardingModalOpened: 'false',
        onboardingCompleted: 'false',
    };
    Object.entries(itemsToSet).forEach(([key, value]) => {
        if (!sessionStorage.getItem(key)) {
            sessionStorage.setItem(key, value);
        }
    });
};
