import { wrapRequest } from '@applandeo/api-wrapper';
import PubSub from 'pubsub-js';
import React, { Dispatch, ReactNode, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FullScreenLoader from '@/components/common/Loaders/FullScreenLoader';
import { ALERT_VARIANT, useAlertMessages } from '@/components/Global/AlertMessages/AlertMessagesProvider';
import AppOutdatedMessage from '@/components/Global/AppOutdatedMessage';
import { PUB_SUB_TOPICS } from '@/components/Global/GlobalDataManagement/pubsub/pubsub';
import { isLoggedIn, logout } from '@/dataExchange/OAuth2Manager';
import { getAllInvitations } from '@/dataExchange/services/auth.service';
import { getAbsenceTypesNew, getOrganizationHolidays } from '@/dataExchange/services/myOrganization.service';
import { getOrganizationData } from '@/dataExchange/services/settings.service';
import { getSubscriptionsPlans, getSubscriptionStatus } from '@/dataExchange/services/subscription.service';
import { getAllUsers, getUserPicture, getWhoAmI } from '@/dataExchange/services/user.service';
import { ExtendedUserData, OrganizationDescriptor } from '@/domain/common';
import { FormattedOrganizationHoliday } from '@/domain/myOrganization';
import { SubscriptionPlanResponseDto, SubscriptionStatusResponseDto } from '@/domain/subscription';
import { useOfflineAlert } from '@/hooks/useNetworkInfo/useNetworkInfo';
import { initMixpanelProps } from '@/mixPanel/mixPanelActions';
import { ROUTES } from '@/pages/Routing/Routing';
import { useSentryActions } from '@/sentry/hooks/useSentryActions';
import { SENTRY_ACTIONS } from '@/sentry/sentryActions';
import { DEFAULT_PRICE_PLAN } from '@/utils/constants/subscriptions';
import { parseIsoDate } from '@/utils/date/date';
import { ACCOUNT_ROLE, USER_STATUS } from '@/utils/enums/common';

import { AUTH_ACTIONS } from './pubsub/authTopic';
import { SYSTEM_ACTIONS } from './pubsub/system';

export interface GlobalAction<T> {
    type: string;
    payload?: T;
}

export enum GDataAction {
    INITIAL_CONFIG = 'INITIAL_CONFIG',
    ORGANIZATION = 'ORGANIZATION',
    ORGANIZATION_MEMBERS = 'ORGANIZATION_MEMBERS',
    SENT_INVITATIONS = 'SENT_INVITATIONS',
    SUBSCRIPTION = 'SUBSCRIPTION',
    USER = 'USER',
    USER_PICTURE = 'USER_PICTURE',
    INIT = 'INIT',
    APPLICATION_FINISHED_INITIALIZATION = 'APPLICATION_FINISHED_INITIALIZATION',
    GLOBAL_ERROR = 'GLOBAL_ERROR',
    LOGOUT = 'LOGOUT',
}

const reducer = (state: AppState, action: GlobalAction<unknown>): AppState => {
    switch (action.type) {
        case GDataAction.INIT:
            return { ...state };
        case GDataAction.USER:
            return {
                ...state,
                loggedInUser: action.payload as ExtendedUserData,
            };
        case GDataAction.INITIAL_CONFIG:
            return {
                ...state,
                config: action.payload as IConfig,
            };
        case GDataAction.LOGOUT:
            return {
                ...state,
                loggedInUser: undefined,
                organization: undefined,
            };
        case GDataAction.USER_PICTURE:
            return {
                ...state,
                loggedInUser: {
                    ...(state.loggedInUser as ExtendedUserData),
                    profilePicture: action.payload as string,
                },
            };
        case GDataAction.ORGANIZATION:
            return {
                ...state,
                organization: action.payload as OrganizationDescriptor,
            };

        case GDataAction.ORGANIZATION_MEMBERS:
            return {
                ...state,
                organizationMembers: action.payload as number,
            };
        case GDataAction.SENT_INVITATIONS:
            return {
                ...state,
                sentInvitations: action.payload as number,
            };
        case GDataAction.SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload as {
                    isLoading: boolean;
                    currentSubscription?: SubscriptionStatusResponseDto;
                    currentPlan?: SubscriptionPlanResponseDto;
                },
            };
        case GDataAction.GLOBAL_ERROR:
            return {
                ...state,
                globalError: (action.payload as { message: string }).message,
            };
        case GDataAction.APPLICATION_FINISHED_INITIALIZATION:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

type IConfig = {
    // m.tkaczyk 04.02.22 - for now environment variables
    FQ_BASE_API_URL?: string;
};

export interface AppState {
    isLoading: boolean;
    globalError?: string;
    loggedInUser?: ExtendedUserData;
    organization?: OrganizationDescriptor;
    subscription: {
        isLoading: boolean;
        currentSubscription?: SubscriptionStatusResponseDto;
        currentPlan?: SubscriptionPlanResponseDto;
    };
    organizationMembers?: number;
    sentInvitations?: number;
    config: IConfig;
}
const initialState: AppState = {
    isLoading: true,
    globalError: undefined,
    loggedInUser: undefined,
    config: {
        FQ_BASE_API_URL: process.env.REACT_APP_FQ_BASE_API_URL,
    },
    subscription: { isLoading: true },
};

export const GlobalDataContext = React.createContext<{
    state: AppState;
    dispatch: Dispatch<GlobalAction<unknown>>;
} | null>(null);

export let globalDispatch: Dispatch<GlobalAction<unknown>>;
export let globalState: AppState = initialState;

export const getOrganizationInfo = async (isAdmin?: boolean) => {
    if (!isLoggedIn()) return;
    const [organizationHolidays, organizationData] = await Promise.all([
        getOrganizationHolidays(),
        getOrganizationData(),
    ]);

    const absenceTypes = isAdmin ? await getAbsenceTypesNew(true) : undefined;

    const formattedHolidays: FormattedOrganizationHoliday[] = organizationHolidays.map(holiday => ({
        ...holiday,
        formattedDate: parseIsoDate(holiday.date),
    }));

    globalDispatch({
        type: GDataAction.ORGANIZATION,
        payload: { holidays: formattedHolidays, data: organizationData, absenceTypes },
    });
};

const getCurrentSubscriptionData = async () => {
    if (!isLoggedIn()) return;

    const [pricePlans, currentSubscription] = await Promise.all([getSubscriptionsPlans(), getSubscriptionStatus()]);

    if (!pricePlans || !currentSubscription) {
        globalDispatch({
            type: GDataAction.SUBSCRIPTION,
            payload: { isLoading: false, currentSubscription: undefined, currentPlan: undefined },
        });
        return;
    }

    const currentPlan = pricePlans.find(el => el._id === currentSubscription.subscriptionPlanId);

    if (!currentPlan) {
        globalDispatch({
            type: GDataAction.SUBSCRIPTION,
            payload: { isLoading: false, currentSubscription, currentPlan: DEFAULT_PRICE_PLAN },
        });
        return;
    }

    globalDispatch({
        type: GDataAction.SUBSCRIPTION,
        payload: { isLoading: false, currentSubscription, currentPlan },
    });
};

export const getSentInvitations = async () => {
    if (!isLoggedIn()) return;

    const organizationMembers = await getAllInvitations();

    if (!organizationMembers) {
        return;
    }

    globalDispatch({
        type: GDataAction.SENT_INVITATIONS,
        payload: organizationMembers.length,
    });
};

const getOrganizationMembers = async () => {
    if (!isLoggedIn()) return;

    const organizationMembers = await getAllUsers({
        page: 1,
        pageSize: 1,
        status: USER_STATUS.ACTIVE,
    });

    globalDispatch({
        type: GDataAction.ORGANIZATION_MEMBERS,
        payload: organizationMembers.totalElements,
    });
};

const GlobalDataManagement = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { createAlert } = useAlertMessages();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { handleSentryAction } = useSentryActions();

    const getUserRequest = wrapRequest(getWhoAmI);
    const getUserPictureRequest = wrapRequest(getUserPicture);

    const onOutdated = useCallback(() => {
        createAlert(ALERT_VARIANT.info, <AppOutdatedMessage />, { persist: true });
    }, [createAlert, t]);

    useEffect(() => {
        dispatch({ type: GDataAction.INIT });
        PubSub.subscribe(PUB_SUB_TOPICS.AUTH, handleLoginAndInitialization);
        PubSub.subscribe(PUB_SUB_TOPICS.SYSTEM, handleLoginAndInitialization);
        PubSub.subscribe(PUB_SUB_TOPICS.OUTDATED, onOutdated);
    }, []);

    useOfflineAlert();

    const handleLoginAndInitialization = async (topic: string, action: number) => {
        if (action === AUTH_ACTIONS.USER_LOGGED_OUT) {
            dispatch({ type: GDataAction.LOGOUT });
            logout();
        }
        if (action === AUTH_ACTIONS.USER_LOGGED_IN || action === SYSTEM_ACTIONS.GLOBAL_INITIALIZED) {
            if (!isLoggedIn()) {
                globalDispatch({
                    type: GDataAction.APPLICATION_FINISHED_INITIALIZATION,
                });
                return;
            }
            const user = await getUserRequest
                .fetch()
                .onAnyError(error => {
                    createAlert(ALERT_VARIANT.error, t('translation_problem_fetching_user_profile'));
                    handleSentryAction(error, SENTRY_ACTIONS.ERROR_GET_USER_PROFILE);
                })
                .onSuccess(user => {
                    const { organization, credentialId } = user;

                    globalDispatch({
                        type: GDataAction.USER,
                        payload: user,
                    });
                    globalDispatch({
                        type: GDataAction.APPLICATION_FINISHED_INITIALIZATION,
                    });
                    initMixpanelProps({ organizationId: organization.organizationId, userId: credentialId });
                })
                .concludeWithPromise();
            if (!user) {
                createAlert(ALERT_VARIANT.error, t('translation_problem_fetching_user_profile'));
                navigate(ROUTES.LOGOUT);
                return;
            }
            user.profilePictureUrl &&
                (await getUserPictureRequest
                    .fetch(user.profilePictureUrl)
                    .onAnyError(error => {
                        createAlert(ALERT_VARIANT.error, t('translation_problem_fetching_user_profile_picture'));
                        handleSentryAction(error, SENTRY_ACTIONS.ERROR_GET_USER_PICTURE);
                    })
                    .onSuccess(user => {
                        globalDispatch({
                            type: GDataAction.USER_PICTURE,
                            payload: user,
                        });
                    }));

            const isAdmin = user.roles?.some(({ role }) => role === ACCOUNT_ROLE.ROLE_ADMINISTRATOR);

            getOrganizationInfo(isAdmin);
            getCurrentSubscriptionData();
            getOrganizationMembers();
            isAdmin && getSentInvitations();
        }
    };
    useEffect(() => {
        PubSub.publish(PUB_SUB_TOPICS.SYSTEM, SYSTEM_ACTIONS.GLOBAL_INITIALIZED);
    }, []);

    globalDispatch = dispatch;
    globalState = state;

    if (state.globalError) {
        throw new Error(JSON.stringify(state.globalError));
    }
    return (
        <GlobalDataContext.Provider value={{ state, dispatch }}>
            {state.isLoading ? <FullScreenLoader /> : children}
        </GlobalDataContext.Provider>
    );
};

export default GlobalDataManagement;
